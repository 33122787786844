import type { UserSettingsConfig } from "../../reduxStore/canvas/userSettings/userSettings.reducer";
import { mapStringEnumToDropdownOptions, sortByLabel } from "../../tools/utils";
import { ApiPermission, ApiUserLanguage } from "../../types/enum";

/**
 * These flags cannot be changed at runtime. They must be set in the
 * /config/env.local.js / GetClientSettings
 *
 * @NOTE - If you change this it also needs to be updated for the BackOffice
 * client settings editor.
 */
export enum StaticFeatureFlag {
  ALLOW_COLLABOARD_AI = "allowCollaboardAi",
  DISABLE_CORS_ANYWHERE = "disableCorsAnywhere",
  DISABLE_REGISTRATION_RECEIVE_NEWS = "disableRegistrationReceiveNews",
  EMBED_FONTS = "embedFonts",
  IN_MEMORY_AUTH_TOKEN = "inMemoryAuthToken",
  LOGIN_PAGE_HELP_BUTTON = "loginPageHelpButton",
  RTL_LANGUAGES = "rtlLanguages",
  RUN_FOR_CAPTURE = "runForCapture",
}

export type RuntimeStaticFeatures = {
  /**
   * @NOTE
   * In order to hide the flag names the values should only ever be set to `true`.
   * If the feature is not required for an environment then the flag should not
   * be present.
   */
  [key in StaticFeatureFlag]?: true;
};

/**
 * These flags can be changed at runtime.
 *
 * @NOTE - If you change this it also needs to be updated for the BackOffice
 * client settings editor.
 */
export enum DynamicFeatureFlag {
  ACTIVITY_LOGBOOK = "activityLogbook",
  AREA_SELECTION = "areaSelection",
  BATCH_USER_IMPORT = "batchUserImport",
  CHAT = "chat",
  CREATE_PROJECT_TOS = "createProjectToS",
  DISABLE_API_KEYS = "disableApiKeys",
  DISABLE_AUTH_MODES = "disableAuthModes",
  DISABLE_DELETE_ACCOUNT = "disableDeleteAccount",
  DISABLE_DOCUMENT = "disableDocument",
  DISABLE_MEDIA = "disableMedia",
  DISABLE_SHARE_EMAIL_INVITE = "disableShareEmailInvite",
  DISABLE_SHARE_EMBED = "disableShareEmbed",
  EMBED = "embed",
  ENABLE_COLLABOARD_AI = "enableCollaboardAi",
  GUEST_USERS_DISABLED = "guestUsersDisabled",
  LICENSING = "licensing",
  LINKS_V2 = "links",
  NO_ECOMMERCE = "noEcommerce",
  PLANS_PAGE = "plansPage",
  PRESENTATION_MODE = "presentationMode",
  PROJECT_VERSIONING = "projectVersioning",
  /**
   * @NOTE - This flag is used when a project is shared with only registered users.
   */
  SHARE_PROJECT_PASSWORD_REQUIRED = "shareProjectPasswordRequired",
  /**
   * @NOTE - This flag is is used when a project is shared with registered users AND guest users.
   */
  SHARE_PROJECT_PASSWORD_REQUIRED_FOR_GUESTS = "shareProjectPasswordRequiredForGuests",
  SPACES = "spaces",
  TEAM_PAGE = "teamPage",
  TILE_TAGS = "tileTags",
  USER_SEARCH = "userSearch",
  USERPRESENCE = "userPresence",
  WEBEX = "webex",
  WELCOME_VIDEO = "welcomeVideo",
  ZOOM_MEETING = "zoomMeeting",
}

export const dynamicFeatureFlagOptions =
  mapStringEnumToDropdownOptions(DynamicFeatureFlag).sort(sortByLabel);

export type RuntimeDynamicFeatures = {
  [key in DynamicFeatureFlag]?: boolean;
};

/**
 * The flags represent settings that can be used by features
 *
 * @NOTE - If you change this it also needs to be updated for the BackOffice
 * client settings editor.
 */
export enum DynamicFeatureConfigFlag {
  AUTO_LOGIN_FROM_INVITE = "autoLoginFromInvite",
  CREATE_PROJECT_TOS_LINKS = "createProjectToSLinks",
  CREATE_PROJECT_TOS_CONTENT = "createProjectToSContent",
  CUSTOM_WELCOME_VIDEO = "customWelcomeVideo",
  DEFAULT_ENABLE_ACTIVITY_LOGBOOK = "defaultEnableActivityLogbook",
  DEFAULT_ENABLE_PROJECT_VERSIONING = "defaultEnableProjectVersioning",
  DEFAULT_SETTINGS_STATE = "defaultSettingsState",
  DIGITAL_WHITEBOARD_HOMEPAGE_URL = "digitalWhiteboardHomepageUrl",
  /**
   * @NOTE - This flag should only be used when email registration is disabled.
   * It is intended for use with a tenant with a custom login provider.
   */
  DISABLE_REGISTRATION_NAME = "disableRegistrationName",
  ENTERPRISE_LICENSE_REQUEST_URL = "enterpriseLicenseRequestUrl",
  EXPORT_PROJECT_EXTENDED_IMAGE_FORMATS = "exportProjectExtendedImageFormats",
  FREE_TRIAL_NOTIFICATION = "freeTrialNotification",
  GUEST_LOGOUT_LINKS = "guestLogoutLinks",
  MIGRATE_PROJECT_ENVIRONMENTS = "migrateProjectEnvironments",
  MINI_BO = "miniBo",
  /** @TODO Evaluate after 6.4.2 release if this should be configurable in the BackOffice or we can use a good hardcoded value */
  PROJECT_LOADING_CONCURRENCY = "projectLoadingConcurrency",
  SHARE_LINK_GUEST_ID_DEFAULT = "shareLinkGuestIdDefault",
  /**
   * @NOTE - This flag is used to set the default share link validity.
   */
  SHARE_LINK_VALIDITY = "shareLinkValidity",
  /**
   * @NOTE - This flag is used to set the default share link validity when sharing with guest users.
   */
  SHARE_LINK_VALIDITY_FOR_GUESTS = "shareLinkValidityForGuests",
  SHARING_MIN_PERMISSION = "sharingMinPermission",
}

export interface FreeTrialNotificationConfiguration {
  enabled?: boolean;
  onboardingTemplateId?: number;
  redirectUrl?: string;
}
/**
 * Define the shape of each configuration for each flag.
 */
export type RuntimeDynamicFeatureConfig = {
  [DynamicFeatureConfigFlag.AUTO_LOGIN_FROM_INVITE]?: boolean;
  [DynamicFeatureConfigFlag.CREATE_PROJECT_TOS_LINKS]?: {
    [key in ApiUserLanguage]?: string[];
  };
  [DynamicFeatureConfigFlag.CREATE_PROJECT_TOS_CONTENT]?: Record<
    string,
    string
  >;
  [DynamicFeatureConfigFlag.CUSTOM_WELCOME_VIDEO]?: {
    sources: string[];
    poster?: string;
  };
  [DynamicFeatureConfigFlag.DEFAULT_ENABLE_ACTIVITY_LOGBOOK]?: boolean;
  [DynamicFeatureConfigFlag.DEFAULT_ENABLE_PROJECT_VERSIONING]?: boolean;
  [DynamicFeatureConfigFlag.DEFAULT_SETTINGS_STATE]?: Partial<UserSettingsConfig>;
  [DynamicFeatureConfigFlag.DIGITAL_WHITEBOARD_HOMEPAGE_URL]?: string;
  [DynamicFeatureConfigFlag.DISABLE_REGISTRATION_NAME]?: boolean;
  [DynamicFeatureConfigFlag.ENTERPRISE_LICENSE_REQUEST_URL]?: string;
  [DynamicFeatureConfigFlag.EXPORT_PROJECT_EXTENDED_IMAGE_FORMATS]?: boolean;
  [DynamicFeatureConfigFlag.FREE_TRIAL_NOTIFICATION]?: FreeTrialNotificationConfiguration;
  // Currently GUEST_LOGOUT_LINKS only has one configuration for redirectUrl,
  // but in the near future it may have configuration for free trial request link,
  // so keep the structure as object for extension. Ref: #8871
  [DynamicFeatureConfigFlag.GUEST_LOGOUT_LINKS]?: {
    redirectUrl?: string;
  };
  [DynamicFeatureConfigFlag.MIGRATE_PROJECT_ENVIRONMENTS]?: {
    name: string;
    uuid: string;
    url: string;
  }[];
  [DynamicFeatureConfigFlag.MINI_BO]?:
    | {
        [key in MiniBoDetailedFlags]?: boolean;
      };
  [DynamicFeatureConfigFlag.PROJECT_LOADING_CONCURRENCY]?: number;
  [DynamicFeatureConfigFlag.SHARE_LINK_GUEST_ID_DEFAULT]?: boolean;
  [DynamicFeatureConfigFlag.SHARE_LINK_VALIDITY]?: ("NO_EXPIRY" | number)[];
  [DynamicFeatureConfigFlag.SHARE_LINK_VALIDITY_FOR_GUESTS]?: (
    | "NO_EXPIRY"
    | number
  )[];
  [DynamicFeatureConfigFlag.SHARING_MIN_PERMISSION]?: ApiPermission;
};

/**
 * The flags must only be used to hide in-development features.
 *
 * These flags must be kept separate from the UISettings flags to prevent
 * circular dependencies.
 *
 * @NOTE - If you change this it also needs to be updated for the BackOffice
 * client settings editor.
 */
export enum DevelopmentFlag {
  DISABLE_IMAGE_QUEUE_THROTTLING = "disableImageQueueThrottling",
  DISABLE_TIMEOUTS = "disableTimeouts",
}

export type RuntimeDevelopmentFeatures = {
  /**
   * @NOTE
   * In order to hide the flag names the values should only ever be set to `true`.
   * If the feature is not required for an environment then the flag should not
   * be present.
   */
  [key in DevelopmentFlag]?: true;
};

// TODO: Move these flags to separate sections within `runtimeConfig`
export type RuntimeConfigFeatures = RuntimeDevelopmentFeatures &
  RuntimeStaticFeatures &
  RuntimeDynamicFeatures &
  RuntimeDynamicFeatureConfig;

/**
 * @NOTE These flags are the dynamic feature flags that can be safely edited by
 * a user using the tenant configuration editor (aka Mini BO).
 *
 * The API endpoint (`/CollaborationHub/SetUISettings`) will only accept certain
 * flags so if you need to add to or modify this list please liaise with the
 * server-side team to ensure the relevant flags are supported.
 *
 * In general this list should not include flags regarding licenses, e-commerce
 * and team management. Nor should it include any static feature flags because
 * these flags will only be applied for tenants and this happens dynamically.
 */
const tenantConfigurationDynamicFeatureFlagWhitelist: DynamicFeatureFlag[] = [
  DynamicFeatureFlag.CHAT,
  DynamicFeatureFlag.DISABLE_API_KEYS,
  DynamicFeatureFlag.DISABLE_AUTH_MODES,
  DynamicFeatureFlag.DISABLE_DELETE_ACCOUNT,
  DynamicFeatureFlag.DISABLE_DOCUMENT,
  DynamicFeatureFlag.DISABLE_MEDIA,
  DynamicFeatureFlag.DISABLE_SHARE_EMAIL_INVITE,
  DynamicFeatureFlag.EMBED,
  DynamicFeatureFlag.ENABLE_COLLABOARD_AI,
  DynamicFeatureFlag.GUEST_USERS_DISABLED,
  DynamicFeatureFlag.LINKS_V2,
  DynamicFeatureFlag.PRESENTATION_MODE,
  DynamicFeatureFlag.SHARE_PROJECT_PASSWORD_REQUIRED,
  DynamicFeatureFlag.SHARE_PROJECT_PASSWORD_REQUIRED_FOR_GUESTS,
  DynamicFeatureFlag.SPACES,
  DynamicFeatureFlag.USERPRESENCE,
];

export const tenantConfigurationDynamicFeatureFlagOptions =
  dynamicFeatureFlagOptions.filter((option) =>
    tenantConfigurationDynamicFeatureFlagWhitelist.includes(
      option.value as DynamicFeatureFlag
    )
  );

/**
 * These flags are used to configure the MiniBo displaying tabs
 */
export enum MiniBoDetailedFlags {
  SSO = "provider",
}
